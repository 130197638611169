@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  /* background: #3a3f51; */
  border-radius: 3px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tooltip {
  z-index: 10000;
}

.v-particles {
  z-index: 0;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.max-w-3-cols {
  max-width: calc((100% / 3) * 3); /* Max width to fit 3 columns */
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

/*ripple effect*/
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.ripple-light {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple-light:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple-light:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.auth-wrapper {
  background-image: url("../public/assets/images/slide/slide-1.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.auth-wrapper .filter-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.auth-wrapper .event-filter-smart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.auth-wrapper .event-filter-smart img {
  width: 50px;
  height: auto;
  margin: 10px;
  transition: 0.3s;
  cursor: pointer;
  opacity: 0.6;
}

.auth-wrapper .event-filter-smart img:hover {
  width: 80px;
  opacity: 1;
}

.auth-wrapper .event-filter-smart .active img {
  opacity: 1;
  width: 80px;
}

.binance-widget-marquee {
  position: fixed;
  bottom: 0;
  height: 50px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .binance-widget-marquee {
    display: none;
  }
}

progress::-webkit-progress-bar {
  background-color: transparent;
}

.pdf-canvas canvas {
  width: 100%;
}

.slick-list {
  width: auto;
  height: 100%;
}
.slick-slider {
  width: 100%;
  height: 100%;
}

/* .slick-arrow {
  display: none;
}

.slick-arrow::before {
  display: none;
} */

.slick-prev,
.slick-next {
  display: none;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-slide {
  width: 100%;
  height: 100%;
}

.slick-list {
  width: 100%;
  height: 100%;
}

.slick-track {
  width: 100%;
  height: 100%;
}
/* .slick-track div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
} */

.pg-viewer-wrapper {
  width: 100%;
}

.react-transform-wrapper {
  width: 100%;
}

.react-transform-component {
  width: 100%;
}
